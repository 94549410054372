import ReactPlayer from "react-player";
import useArrowKeys from "use-arrow-keys";
import CloseIcon from "@mui/icons-material/Close";
import "../../../assets/styles/containers/_carousel.scss";
import React, { useEffect, useRef, useState } from "react";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { Box, Grid, IconButton, Modal } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const ImageCarousel = ({ images, open, handleClose, index }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState();
  const carouselItemsRef = useRef([]);

  images?.sort((a, b) => {
    if (a?.cat === "Nameplate" && b?.cat !== "Nameplate") {
      return -1; // a comes before b
    } else if (a?.cat !== "Nameplate" && b?.cat === "Nameplate") {
      return 1; // a comes after b
    } else {
      return 0; // no change in order
    }
  });
  useEffect(() => {
    if (images && images[0]) {
      let ImageIndex = images?.findIndex((e) => e?.media_id === index);
      carouselItemsRef.current = carouselItemsRef.current.slice(0, images.length);
      handleSelectedImageChange(ImageIndex);
    }
    // eslint-disable-next-line
  }, [images]);

  const handleSelectedImageChange = (newIdx) => {
    if (images && images.length > 0) {
      setSelectedImage(images[newIdx]);
      setSelectedImageIndex(newIdx);
      if (carouselItemsRef?.current[newIdx]) {
        carouselItemsRef?.current[newIdx]?.scrollIntoView({
          // inline: "center",
          behavior: "smooth",
          block: "end",
          inline: "start",
        });
      }
    }
  };

  const handleRightClick = () => {
    if (images && images.length > 0) {
      let newIdx = selectedImageIndex + 1;
      if (newIdx >= images.length) {
        newIdx = 0;
      }
      handleSelectedImageChange(newIdx);
    }
  };

  const handleLeftClick = () => {
    if (images && images.length > 0) {
      let newIdx = selectedImageIndex - 1;
      if (newIdx < 0) {
        newIdx = images.length - 1;
      }
      handleSelectedImageChange(newIdx);
    }
  };

  const onLeftKey = () => handleLeftClick();
  const onRightKey = () => handleRightClick();
  useArrowKeys({ onLeftKey, onRightKey });
  return (
    <Modal open={open} onClose={handleClose} className="carousel-modal" sx={{ backgroundColor: "rgb(25,25,25,0.8)" }}>
      <Box className="carousel-modal-layout">
        <Grid container sx={{ p: 2 }}>
          <Grid item xs={12}>
            <CloseIcon className="carousel-close-icon" onClick={handleClose} />
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={2} sx={{ verticalAlign: "middle" }}>
                <IconButton className="carousel-navigate-button-prev" sx={{ float: "right !important" }} onClick={handleLeftClick}>
                  <ChevronLeftIcon sx={{ float: "right !important" }} />
                </IconButton>
              </Grid>
              <Grid item xs={8}>
                {selectedImage?.type?.includes("image") ? (
                  <div className="selected-image" style={{ backgroundImage: `url(${selectedImage?.url})` }} />
                ) : (
                  <div style={{ width: "100%", justifyContent: "center", display: "flex" }}>
                    <ReactPlayer controls height="65vh" width="fit-content" url={selectedImage?.url} />
                  </div>
                )}
                <div style={{ height: "6vh", overflowY: "scroll" }}>
                  <p style={{ color: "white", textAlign: selectedImage?.desc?.length > 100 ? "justify" : "center" }}>{selectedImage?.desc ? selectedImage?.desc : ""}</p>
                </div>
              </Grid>
              <Grid item xs={2}>
                <IconButton className="carousel-navigate-button-next" onClick={handleRightClick}>
                  <ChevronRightIcon />
                </IconButton>
              </Grid>
              <Grid item xs={0} md={6}></Grid>
              <Grid item xs={12} md={6} sx={{ position: "absolute", bottom: 0, right: 0 }}>
                {selectedImage?.cat && selectedImage?.cat !== "null" ? <p style={{ color: "white" }}>{selectedImage?.cat.length > 20 ? selectedImage?.cat.substring(0, 20) + "..." : selectedImage?.cat}</p> : ""}
                <div style={{ overflowX: "scroll" }} tabIndex="1">
                  <div
                    id="stage"
                    onKeyDown={(e) => {
                      if (e.key === "ArrowRight") {
                        handleRightClick();
                      }
                      if (e.key === "ArrowLeft") {
                        handleLeftClick();
                      }
                    }}
                    tabIndex="0"
                    className="carousel__images"
                  >
                    {images &&
                      images?.map((image, idx) =>
                        image?.type?.includes("video") ? (
                          <div onClick={() => handleSelectedImageChange(idx)} style={{ backgroundImage: `url(${image.type.includes("video") ? image.thumb : image.url})`, position: "relative" }} key={image.id} className={`carousel__image ${selectedImageIndex === idx && "carousel__image-selected"}`} ref={(el) => (carouselItemsRef.current[idx] = el)}>
                            {image?.type?.includes("video") ? <PlayCircleIcon className="media-play-icon-thumbnail" /> : ""}
                          </div>
                        ) : (
                          <div onClick={() => handleSelectedImageChange(idx)} style={{ backgroundImage: `url(${image.type.includes("video") ? image.thumb : image.url})` }} key={image.id} className={`carousel__image ${selectedImageIndex === idx && "carousel__image-selected"}`} ref={(el) => (carouselItemsRef.current[idx] = el)}></div>
                        )
                      )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ImageCarousel;
