// file import
import toast from "react-hot-toast";
import { saveAs } from "file-saver";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../config/app-constants";
import { useReactToPrint } from "react-to-print";
import Header from "../components/Header/Header";
import { useState, useEffect, useRef } from "react";
import { Routes as Switch, Route } from "react-router-dom";
import { useLocation, useSearchParams } from "react-router-dom";
//Component
import Pdf from "../pages/pdf/Pdf";
import Media from "../pages/media/Media";
import Preloader from "../helpers/Preloader";
import Summary from "../pages/summary/Summary";
import FullReport from "../pages/fullReport/FullReport";
import PageNotFound from "../common/alert/PageNotFound";
import useMediaQuery from "@mui/material/useMediaQuery";
import Attachments from "../pages/Attachements/Attachments";
//Api call function import
import { apiCall } from "../utils/action";
import Preview from "../pages/Preview/Preview";
import MapView from "../pages/MapView/MapView";
import "../../assets/styles/colors/_colors.scss";
import RequestList from "../pages/RequestList/RequestList";
import DeliveryPage from "../pages/DeliveryPage/DeliveryPage";
import DeliveryPageV1 from "../pages/DeliveryPage/Deliveryv1/DeliveryPageV1";
import DeliveryPageV2 from "../pages/DeliveryPage/Deliveryv2/DeliveryPageV2";
import { getCommentReqListId, originBase } from "../helpers/HelperFunctions";

const Router = () => {
  let BASE_URL = originBase();
  const location = useLocation();
  const navigate = useNavigate();
  const [logo, setLogo] = useState();
  const pathName = location.pathname;
  const [isISN, setIsISN] = useState();
  const [pdfURL, setPdfUrl] = useState(null);
  // const [tocLength, setTocLength] = useState();
  const [showPDF, setShowPDF] = useState(false);
  const [selected, setSelected] = useState(null);
  const [reRender, setReRender] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [isPDFLoader, setIsPDFLoader] = useState(false);
  const [isNarrative, setIsNarrative] = useState(false);
  const [isPDFTemplate, setPDFTemplate] = useState();
  const matches = useMediaQuery("(min-width:900px)");
  const [isDataPorch, setDataPorch] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isTexasReport, setIsTexasReport] = useState();
  const [colorCode, setColorCode] = useState("#394B8A");
  const [isPDFLoaded, setIfPDFLoaded] = useState(false);
  // const [Toc, setToc] = useState({ pageNumberArray: [] });
  const [sampleReport, setSampleReport] = useState(false);
  const [companySettings, setCompanySettings] = useState();
  const [filteredRating, setFilteredRating] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedPDF, setSelectedPDF] = useState("default");
  const [sampleVideoLink, setSampleVideoLink] = useState("");
  // const [isTocGenerated, setIsTocGenerated] = useState(false);
  const [sampleVideoReport, setSampleVideoReport] = useState(false);
  const [sampleComentReqListId, setSampleComentReqListId] = useState([]);
  /**
   * Retrieves report data from the API.
   * @param {string} type - The type of report data to retrieve.
   */
  const getReportData = (type) => {
    // Show loader unless type is "noLoader"
    type !== "noLoader" && setShowLoader(true);

    // Prepare data for API call
    let data = { guid: sessionStorage.getItem("guid") };

    // Make API call
    apiCall(
      (response) => {
        // Initialize variables
        let uniq = {};
        let ratingData = [];

        // Destructure response data
        const { data, message, success } = response.data;

        if (success) {
          // Update report data state
          setReportData(data);

          // Check if Texas report exists
          let texasReport = data?.inspectiontemplates?.filter((e) => e?.is_texas === 1);
          setIsTexasReport(texasReport?.length > 0 ? true : false);

          // Check if data should be sent to Porch
          setDataPorch(data?.send_signal_to_porch === 1 ? true : false);

          // Set logo
          setLogo(data.cover_photo?.onlineUrl);

          // Check if ISN report
          setIsISN(data.is_isn === 1 ? true : false);

          // Set PDF template
          setPDFTemplate(data?.non_pdf_template);

          //Set Is Narrative
          setIsNarrative(data?.narrative_template_exists === 1 ? true : false);

          // Update local storage
          localStorage.setItem("address", data.inspection_details?.address);

          // Update primary color in session storage and CSS
          sessionStorage.setItem("primaryColor", data?.company_html_report_setting?.color_code);
          // setTocLength(() => {
          //   // Filter templates based on the condition
          //   const filteredTemplates = data?.inspectiontemplates?.filter((template) => template?.is_pdf_template === 0);

          //   // Calculate total categories length
          //   const categoriesLength = filteredTemplates?.flatMap((template) => template?.categories || []).length;

          //   // Calculate total number of templates
          //   const templateLength = filteredTemplates?.length || 0;

          //   return categoriesLength + templateLength * 2 + 2;
          // });

          // Hide loader and set data loaded flag
          setShowLoader(false);
          setDataLoaded(true);

          // Set color code and update CSS
          setColorCode(!data?.company_html_report_setting?.color_code || data?.company_html_report_setting?.color_code === "null" ? data?.narrative_template_exists === 1 ? "#D31145" : "#394B8A" : data?.company_html_report_setting?.color_code);
          document.documentElement.style.setProperty("--default-primary-color", !data?.company_html_report_setting?.color_code || data?.company_html_report_setting?.color_code === "null" ? data?.narrative_template_exists === 1 ? "#D31145" : "#394B8A" : data?.company_html_report_setting?.color_code);

          // Update company settings state
          setCompanySettings(data.company_html_report_setting);

          // Prepare rating data for filtering
          data?.inspectiontemplates?.forEach((template) => template.rating_data.forEach((rating) => ratingData.push(rating)));
          let filteredRatingArray = ratingData.filter((obj) => !uniq[obj.Name.toLowerCase()] && (uniq[obj.Name.toLowerCase()] = true));
          setFilteredRating(filteredRatingArray);

          // Set sample report flags and video link
          setSampleReport(data?.is_sample_report === 1 ? true : false);
          setSampleVideoReport(data?.is_video_sample_report === 1 ? true : false);
          setSampleVideoLink(data?.is_video_sample_report === 1 ? data?.sample_report_video_url : "");

          // Get comment requirement list IDs
          let comtReqIds = getCommentReqListId(data?.inspectiontemplates);
          setSampleComentReqListId(comtReqIds);
        } else {
          // Show error toast message
          toast.error(message);
          setShowLoader(false);
        }
      },
      data,
      "GET_REPORT_DATA"
    );
  };


  /**
   * Send data to Porch API.
   *
   * @param {string} type - The type of data to send.
   */
  const sendDataToPorch = (type) => {
    // Create the data object with the guid from session storage
    let data = { guid: sessionStorage.getItem("guid") };
    // Make an API call to send the data to Porch
    apiCall(
      (response) => {
        const { message, success } = response.data;
        // Log the message based on the success of the API call
        if (success) {
          console.log(message);
        } else {
          console.log(message);
        }
      },
      data,
      "SEND_DATA_TO_PORCH"
    );
  };

  // Print current view
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  // Get report data
  useEffect(() => {
    if (pathName !== "/preview-report/") {
      if (sessionStorage.getItem("guid") === null || sessionStorage.getItem("guid") === "null") {
        sessionStorage.setItem("guid", searchParams.get("guid"));
        searchParams.delete("guid");
        setSearchParams(searchParams);
      }
    }
    if (pathName !== "/delivery/" && pathName !== "/delivery_v1/" && pathName !== "/delivery_v2/" && pathName !== "/mapview" && pathName !== "/preview-report/") {
      getReportData("normalLoad");
    }
    if (sessionStorage.getItem("headless") === null || sessionStorage.getItem("headless") === "null") {
      sessionStorage.setItem("headless", searchParams.get("headless"));
      searchParams.delete("headless");

      setSearchParams(searchParams);
    }
    // eslint-disable-next-line
  }, [reRender]);

  // Run the effect whenever `isTexasReport` or `showPDF` changes
  useEffect(() => {
    // Check if it's a Texas report and PDF is not shown yet
    if (isTexasReport && !showPDF) {
      // Set `showPDF` to true
      setShowPDF(true);
      // Navigate to the "/pdf" route
      navigate("/pdf");
    }
    // eslint-disable-next-line
  }, [isTexasReport, showPDF]);

  const openPdf = (argument) => {
    const worker = new Worker(new URL('../pages/pdf/pdfWorker.js', import.meta.url));

    if (!isPDFLoaded) {
      worker.onmessage = (e, value) => {
        if (e.data.error) {
          toast.error('Error generating PDF:', e.data.error);
        } else {
          // Revoke the previous Blob URL if it exists
          if (pdfURL) {
            URL.revokeObjectURL(pdfURL);
          }
          // Create a new Blob URL and set it in the state
          setPdfUrl(URL.createObjectURL(e.data));
          setIfPDFLoaded(true)
          if (argument === 1) {
            saveAs(e.data, "Home Inspection Report");
          }
        }
      };
    } else if (isPDFLoaded) {
      if (argument === 1) {
        saveAs(pdfURL, "Home Inspection Report");
      }
    }

    // Trigger the worker to generate the PDF
    worker.postMessage({ value: reportData, isTexasReport: isTexasReport, url: `${window.location.origin}/?guid=${sessionStorage.getItem("guid")}` });

    // Cleanup function to revoke Blob URL when the component unmounts
    return () => {
      if (pdfURL) {
        URL.revokeObjectURL(pdfURL);
      }
    };
  }

  useEffect(() => {
    if (dataLoaded) {
      openPdf();
    }
  }, [dataLoaded])

  const rwPDFGenerate = () => {
    const formData = { guid: sessionStorage.getItem("guid") };
    apiCall(
      (response) => {
        const { success, message } = response.data;
        if (success) {
          sendDataToPorch();
        } else {
          console.log("RW_PDF_GENERATE api", message);
        }
      },
      formData,
      "RW_PDF_GENERATE"
    );
  };

  useEffect(() => {
    if (dataLoaded && isDataPorch && sessionStorage.getItem('headless') === 'null') rwPDFGenerate();
  }, [isDataPorch, dataLoaded]);

  /**
   * Uploads a PDF file to the server.
   *
   * @param {Blob} blobData - The PDF file in Blob format.
   */
  const uploadPDF = (blobData) => {
    /**
     * Converts a Blob to base64 format.
     *
     * @param {Blob} blob - The Blob to convert.
     * @returns {Promise<string>} - The base64 representation of the Blob.
     */
    function blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.split(",")[1]);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    }

    blobToBase64(blobData).then((base64Data) => {
      let data = { guid: sessionStorage.getItem("guid"), pdf_file: base64Data };

      /**
       * Handles the response from the API call.
       *
       * @param {object} response - The response object from the API call.
       */
      apiCall(
        (response) => {
          const { data, message, success } = response.data;
          if (success) {
            console.info(message, data);
            // if (isDataPorch) {
            //   sendDataToPorch();
            // }
          } else {
            toast.error(message);
          }
        },
        data,
        "UPLOAD_PDF"
      );
    });
  };

  return (
    <>
      {/* Check if data is loaded */}
      {dataLoaded || pathName === "/delivery/" || pathName === "/delivery_v1/" || pathName === "/delivery_v2/" ? <Helmet>{isISN ? <link rel="icon" href="logo-isn.png" type="image/png" /> : pathName === "/delivery/" || pathName === "/delivery_v1/" || pathName === "/delivery_v2/" ? <link rel="icon" href={`${BASE_URL}/logo-pt.ico`} type="image/png" /> : <link rel="icon" href="logo-pt.ico" type="image/png" />}</Helmet> : ""}
      {/* Preloader */}
      <Preloader showPreloader={showLoader} isPDFLoader={isPDFLoader} />
      {!showLoader && <>
        {!(pathName === "/repair-list" && matches) && pathName !== "/delivery/" && pathName !== "/delivery_v1/" && pathName !== "/delivery_v2/" && pathName !== "/preview" && pathName !== "/preview-report/" && pathName !== "/mapview" && <Header isNarrative={isNarrative} isISN={isISN} inspectionStatus={reportData?.inspection_details?.status} selectedPDF={selectedPDF} setSelectedPDF={setSelectedPDF} categoriesData={reportData?.inspectiontemplates} isPDFTemplate={isPDFTemplate} companySettings={companySettings} attachments={reportData?.attachments} setSelected={setSelected} inspectorData={reportData?.inspection_details?.orderinspector} clientData={reportData?.inspection_details?.orderclient} handlePrint={handlePrint} openPdf={openPdf} pdfURL={pdfURL} isPDFLoaded={isPDFLoaded} sampleReport={sampleReport} sampleVideoReport={sampleVideoReport} sampleVideoLink={sampleVideoLink} sampleComentReqListId={sampleComentReqListId} setSampleComentReqListId={setSampleComentReqListId} />}
        <Switch>
          {/* Routes */}
          <Route path={ROUTES.PREVIEW} exact element={<Preview />} />
          <Route path={ROUTES.MAPVIEW} exact element={<MapView />} />
          <Route path={ROUTES.SHARED_PREVIEW} exact element={<Preview />} />
          <Route path={ROUTES.DELIVERY} exact element={<DeliveryPage />} />
          <Route path={ROUTES.DELIVERYV1} exact element={<DeliveryPageV1 />} />
          <Route path={ROUTES.DELIVERYV2} exact element={<DeliveryPageV2 />} />
          <Route path={ROUTES.MEDIA} exact element={<Media isNarrative={isNarrative} isPDFTemplate={isPDFTemplate} reportData={reportData} showLoader={showLoader} isISN={isISN} />} />
          <Route path={ROUTES.ATTACHMENTS} exact element={<Attachments isNarrative={isNarrative} selected={selected} setSelected={setSelected} attachments={reportData?.attachments} isISN={isISN} showLoader={showLoader} />} />
          <Route path={ROUTES.PDF} exact element={<Pdf isNarrative={isNarrative} colorCode={colorCode} companySettings={companySettings} reportData={reportData} setPdfUrl={setPdfUrl} setIfPDFLoaded={setIfPDFLoaded} isTexasReport={isTexasReport} setIsPDFLoader={setIsPDFLoader} setShowLoader={setShowLoader} dataLoaded={dataLoaded} isISN={isISN} openPdf={openPdf} data={reportData} inspectionStatus={reportData?.inspection_details?.status} selectedPDF={selectedPDF} setSelectedPDF={setSelectedPDF} isPDFTemplate={isPDFTemplate} pdfURL={pdfURL} isPDFLoaded={isPDFLoaded} categoriesData={reportData?.inspectiontemplates} />} />
          <Route path={ROUTES.SUMMARY} exact element={<Summary isNarrative={isNarrative} reportData={reportData} componentRef={componentRef} isISN={isISN} showLoader={showLoader} />} />
          <Route path={ROUTES.FALLBACK} exact element={<PageNotFound type="404" message="Page not found" action="Redirect" />} />
          <Route path={ROUTES.HOME} exact element={<FullReport isNarrative={isNarrative} isPDFTemplate={isPDFTemplate} companySettings={companySettings} showLoader={showLoader} isISN={isISN} logo={logo} reportData={reportData} isTexasReport={isTexasReport} showPDF={showPDF} componentRef={componentRef} sampleReport={sampleReport} />} />
          <Route path={ROUTES.REQUEST_LIST} exact element={<RequestList filteredRating={filteredRating} reportData={reportData} setReRender={setReRender} getReportData={getReportData} isISN={isISN} showLoader={showLoader} sampleReport={sampleReport} setSampleComentReqListId={setSampleComentReqListId} />} />
        </Switch>
      </>}
    </>
  );
};

export default Router;