import ImageCarousel from "../../common/Carousel/ImageCarousel";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { Box, Grid, CssBaseline } from "@mui/material";
import "../../../assets/styles/containers/_media.scss";
import SideMenu from "../../components/Menu/SideMenu";
import { Typography } from "@material-ui/core";
import { useState } from "react";

const Media = (props) => {
  let media = [];
  const temp = [];
  const { reportData, isISN, showLoader, isNarrative } = props;
  const [index, setIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const openCarousel = (url) => {
    setOpen(true);
    setIndex(url);
  };
  window.scrollTo(0, 0);

  function getFilteredTemplatesWithMedia(reportData) {
    return reportData?.inspectiontemplates
      ?.filter((e) => e?.is_media === 1 || e?.is_media === true)
      ?.filter((e) => e?.is_pdf_template !== 1)
      ?.some((templateValue) => {
        const hasMedia =
          templateValue?.categories
            ?.filter((e) => e?.is_deleted !== 1)
            ?.some((categoriesValue) =>
              categoriesValue?.lines
                ?.filter((e) => e?.is_deleted !== 1)
                ?.some((lineValue) =>
                  lineValue?.line_input_controls?.some(
                    (mediaValue) =>
                      mediaValue?.type === "Media" &&
                      mediaValue?.data?.filter((e) => e?.is_deleted !== 1)
                        ?.length > 0
                  )
                )
            ) ||
          templateValue?.categories?.some((categoriesValue) =>
            categoriesValue?.lines
              ?.filter((e) => e?.is_deleted !== 1)
              ?.some((lineValue) =>
                lineValue?.comments
                  ?.filter((e) => e?.is_deleted !== 1)
                  ?.some((comValue) =>
                    comValue?.comment_input_controls?.some(
                      (mediaValue) =>
                        mediaValue?.type === "Media" &&
                        mediaValue?.data?.filter((e) => e?.is_deleted !== 1)
                          ?.length > 0
                    )
                  )
              )
          );

        return hasMedia;
      });
  }

  return (
    <>
      <Box className="media-box">
        <CssBaseline />
        <SideMenu isNarrative={isNarrative} categoriesData={getFilteredTemplatesWithMedia(reportData) ? reportData?.inspectiontemplates : []} isISN={isISN} showLoader={showLoader} />
        <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
          <div style={{ height: "140px" }}></div>
          {/* <motion.div initial={{ opacity: 0.5 }} animate={{ opacity: 1 }} className="animate-div"> */}
          {getFilteredTemplatesWithMedia(reportData) && reportData?.inspectiontemplates?.filter((e) => e?.is_media === 1 || e?.is_media === true)?.filter((e) => e?.is_pdf_template !== 1)?.map((templateValue, templateIndex) => {
            return (
              <>
                {templateValue?.is_media === 1 ? (
                  <Grid container className="media-container" spacing={1}>
                    <Grid item xs={12}>
                      <div className="template-heading-media">
                        <Typography className="media-template-title">{templateValue.report_title ? templateValue.report_title : templateValue.name}</Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        {templateValue?.categories
                          ?.filter((value) => value?.is_inspection_details !== 1 && value?.is_inspection_details !== 2)
                          ?.map((categoriesValue, categoriesIndex) => {
                            return (
                              <>
                                {categoriesValue?.lines?.filter(e => e?.is_deleted === 0)?.forEach((value) => {
                                  value?.line_input_controls?.filter((lineVal) => lineVal?.type === "Media")?.forEach((lineICval) => (lineICval?.data?.length > 0 ? lineICval?.data?.forEach((e) => (e.online === "online" && (e.type?.includes("image") || e.type?.includes("video")) ? temp.push(true) : temp.push(false))) : temp.push(false)));
                                  value?.comments?.forEach((comValue) => {
                                    comValue?.comment_input_controls?.filter((lineVal) => lineVal?.type === "Media")?.forEach((lineICval) => (lineICval?.data?.length > 0 ? lineICval?.data?.forEach((e) => (e.online === "online" && (e.type?.includes("image") || e.type?.includes("video")) ? temp.push(true) : temp.push(false))) : temp.push(false)));
                                  });
                                })}
                                <Grid container id={`${categoriesValue?.prompt}_${categoriesValue?.category_id}`}>
                                  <Grid item xs={12}>
                                    {temp?.some((el) => el === true) ? <Typography className="media-title">{`${categoriesIndex + 1}. ${categoriesValue?.prompt ? categoriesValue?.prompt : ""}`}</Typography> : ""}
                                  </Grid>
                                  {categoriesValue?.lines?.filter((e) => e.is_deleted === 0)?.map((lineValue, lineIndex) => {
                                    return (
                                      <>
                                        {lineValue?.line_input_controls
                                          ?.filter((value) => value?.type === "Media")
                                          ?.map((mediaValue) => {
                                            return mediaValue?.data.length > 0
                                              ? mediaValue?.data?.map((mediaVal, index) => {
                                                return mediaVal.type?.includes("image") && mediaVal.online === "online" ? (
                                                  <Grid item xs={12} sm={6} md={3} lg={3} className="image-container">
                                                    <div className="lib-div">{media.push({ url: mediaVal.onlineUrl, type: mediaVal.type, desc: mediaVal.description, media_id: `${mediaVal?.uuid}-${mediaVal?.created_at}`, cat: `${categoriesIndex + 1}.${categoriesValue.prompt ? categoriesValue.prompt : ""}` })}</div>
                                                    <img onClick={() => openCarousel(`${mediaVal?.uuid}-${mediaVal?.created_at}`)} className="media-image-container" src={mediaVal?.thumbnail ? mediaVal?.thumbnail : mediaVal.onlineUrl} alt="in"></img>
                                                    <p className={isNarrative ? "narrative-media-description" : "media-description"}>{mediaVal?.description}</p>
                                                  </Grid>
                                                ) : mediaVal.type?.includes("video") ? (
                                                  <Grid item xs={12} sm={6} md={3} lg={3} className="video-container">
                                                    <Grid container>
                                                      <Grid item xs={12} sx={{ position: "relative" }}>
                                                        <div className="lib-div">{media.push({ url: mediaVal.onlineUrl, type: mediaVal.type, thumb: mediaVal.thumbnail, desc: mediaVal.description, media_id: `${mediaVal?.uuid}-${mediaVal?.created_at}`, cat: `${categoriesIndex + 1}. ${categoriesValue.prompt ? categoriesValue.prompt : ""}` })}</div>
                                                        <img onClick={() => openCarousel(`${mediaVal?.uuid}-${mediaVal?.created_at}`)} className="media-image-container" src={mediaVal.thumbnail} alt="in"></img>
                                                        <PlayCircleIcon className="media-play-icon" onClick={() => openCarousel(`${mediaVal?.uuid}-${mediaVal?.created_at}`)} />
                                                      </Grid>
                                                      <Grid item xs={12}>
                                                        <p className={isNarrative ? "narrative-media-description" : "media-description"} style={{ textAlign: mediaVal?.description?.length > 100 ? "justify" : "center" }}>
                                                          {mediaVal?.description}
                                                        </p>
                                                      </Grid>
                                                    </Grid>
                                                  </Grid>
                                                ) : (
                                                  ""
                                                );
                                              })
                                              : "";
                                          })}
                                        {lineValue?.comments?.filter((e) => e?.is_deleted !== 1)?.map((comValue, lineIndex) => {
                                          return (
                                            <>
                                              {comValue?.comment_input_controls
                                                ?.filter((value) => value?.type === "Media")
                                                ?.map((mediaValue) => {
                                                  return mediaValue?.data.length > 0
                                                    ? mediaValue?.data?.map((mediaVal) => {
                                                      return mediaVal.type?.includes("image") && mediaVal.online === "online" ? (
                                                        <Grid item xs={12} sm={6} md={3} lg={3} className="image-container">
                                                          <div className="lib-div">{media.push({ url: mediaVal.onlineUrl, type: mediaVal.type, desc: mediaVal.description, media_id: `${mediaVal?.uuid}-${mediaVal?.created_at}`, cat: `${categoriesIndex + 1}.${categoriesValue.prompt ? categoriesValue.prompt : ""}` })}</div>
                                                          <img onClick={() => openCarousel(`${mediaVal?.uuid}-${mediaVal?.created_at}`)} className="media-image-container" src={mediaVal?.thumbnail ? mediaVal?.thumbnail : mediaVal.onlineUrl} alt="in"></img>
                                                          <p className={isNarrative ? "narrative-media-description" : "media-description"}>{mediaVal?.description}</p>
                                                        </Grid>
                                                      ) : mediaVal.type?.includes("video") ? (
                                                        <Grid item xs={12} sm={6} md={3} lg={3} className="video-container">
                                                          <Grid container>
                                                            <Grid item xs={12} sx={{ position: "relative" }}>
                                                              <div className="lib-div">{media.push({ url: mediaVal.onlineUrl, type: mediaVal.type, thumb: mediaVal.thumbnail, media_id: `${mediaVal?.uuid}-${mediaVal?.created_at}`, desc: mediaVal.description, cat: `${categoriesIndex + 1}.${categoriesValue.prompt ? categoriesValue.prompt : ""}` })}</div>
                                                              <img onClick={() => openCarousel(`${mediaVal?.uuid}-${mediaVal?.created_at}`)} className="media-image-container" src={mediaVal.thumbnail} alt="in"></img>
                                                              <PlayCircleIcon className="media-play-icon" onClick={() => openCarousel(`${mediaVal?.uuid}-${mediaVal?.created_at}`)} />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                              <p className={isNarrative ? "narrative-media-description" : "media-description"} style={{ textAlign: mediaVal?.description?.length > 100 ? "justify" : "center" }}>
                                                                {mediaVal?.description}
                                                              </p>
                                                            </Grid>
                                                          </Grid>
                                                        </Grid>
                                                      ) : (
                                                        ""
                                                      );
                                                    })
                                                    : "";
                                                })}
                                            </>
                                          );
                                        })}
                                        <div className="lib-div">{(temp.length = 0)}</div>
                                      </>
                                    );
                                  })}
                                </Grid>
                              </>
                            );
                          })}
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
              </>
            );
          })}
          {reportData?.inspectiontemplates
            ?.filter((item) => {
              if (item.hasOwnProperty("is_pdf_template")) {
                return item["is_pdf_template"] === 1;
              } else {
                return true;
              }
            })
            ?.filter((e) => e?.is_media === 1 || e?.is_media === true)?.map((templateValue, templateIndex) => {
              return (
                <>
                  <Grid container className="media-container" spacing={1} id={`${templateValue.name}`}>
                    <Grid item xs={12}>
                      <div className="template-heading-media">
                        <Typography className="media-template-title">{templateValue.report_title ? templateValue.report_title : templateValue.name}</Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        {templateValue?.pdf_categories?.map((categoriesValue, categoriesIndex) => {
                          return (
                            <>
                              <Grid container>
                                <Grid item xs={12} sx={{ mt: 1 }}>
                                  <Typography className="media-title">{categoriesValue?.prompt}</Typography>
                                </Grid>
                                {categoriesValue?.elements?.map((elementValue) => {
                                  return (
                                    <>
                                      <Grid item xs={12} sx={{ fontSize: "8px !important" }}>
                                        <Typography className="submedia-title">{elementValue?.label}</Typography>
                                      </Grid>
                                      {elementValue?.media?.map((mediaVal, index) => {
                                        return mediaVal.type?.includes("image") && mediaVal.online === "online" ? (
                                          <Grid item xs={12} sm={6} md={3} lg={3} className="image-container">
                                            <div className="lib-div">{media.push({ url: mediaVal.onlineUrl, type: mediaVal.type, desc: mediaVal.description, media_id: `${mediaVal?.uuid}-${mediaVal?.created_at}`, cat: `${categoriesIndex + 1}.${categoriesValue.prompt}` })}</div>
                                            <img onClick={() => openCarousel(`${mediaVal?.uuid}-${mediaVal?.created_at}`)} className="media-image-container" src={mediaVal?.thumbnail ? mediaVal?.thumbnail : mediaVal.onlineUrl} alt="in"></img>
                                            <p className="media-description">{mediaVal?.description}</p>
                                          </Grid>
                                        ) : mediaVal.type?.includes("video") ? (
                                          <Grid item xs={12} sm={6} md={3} lg={3} className="video-container">
                                            <div className="lib-div">{media.push({ url: mediaVal.onlineUrl, type: mediaVal.type, thumb: mediaVal.thumbnail, media_id: `${mediaVal?.uuid}-${mediaVal?.created_at}`, desc: mediaVal.description, cat: `${categoriesIndex + 1}.${categoriesValue.prompt}` })}</div>
                                            <img onClick={() => openCarousel(`${mediaVal?.uuid}-${mediaVal?.created_at}`)} className="media-image-container" src={mediaVal.thumbnail} alt="in"></img>
                                            <PlayCircleIcon className="media-play-icon" onClick={() => openCarousel(`${mediaVal?.uuid}-${mediaVal?.created_at}`)} />
                                            <p className="media-description">{mediaVal?.description}</p>
                                          </Grid>
                                        ) : (
                                          ""
                                        );
                                      })}
                                    </>
                                  );
                                })}
                              </Grid>
                            </>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              );
            })}
          {/* </motion.div> */}
        </Box>
      </Box>
      <ImageCarousel images={media} index={index} open={open} handleClose={handleClose} />
    </>
  );
};
export default Media;