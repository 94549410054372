import { useEffect, useState } from "react";
import "@react-pdf-viewer/core/lib/styles/index.css";
import AttachmentSideMenu from "./AttachmentSideMenu";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { Alert, Box, Grid, CssBaseline } from "@mui/material";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import ImageCarousel from "../../common/Carousel/ImageCarousel";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

const Attachments = ({ attachments, selected, setSelected, isISN, showLoader, isNarrative }) => {
  let pdf = [];
  let images = [];
  const [index, setIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const [defaultCheckFlag, setDefaultCheckFlag] = useState(false);
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [defaultTabs[0]],
  });
  attachments?.forEach((value, index) => {
    if (value.type?.includes("image")) {
      images.push({ type: value.type, id: index, uuid: value?.uuid, created_at: value?.created_at, media_id: `${value?.uuid}-${value?.created_at}`, description: value.description, name: value.name, url: value.onlineUrl, thumbnail: value?.thumbnail });
    }
    if (value.type === "application/pdf") {
      pdf.push({ id: index, name: value.description, description: value.name, url: value.onlineUrl });
    }
  });
  
  return (
    <Box sx={{ display: "flex", overflowX: "hidden" }}>
      <CssBaseline />
      <AttachmentSideMenu isNarrative={isNarrative} pdfData={pdf} setDefaultCheckFlag={setDefaultCheckFlag} defaultCheckFlag={defaultCheckFlag} imageData={images} setSelected={setSelected} isISN={isISN} showLoader={showLoader} />
      <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
        <Grid container sx={{ mt: "160px" }}>
          {selected === "images"
            ? images.map((value, index) => {
              return (
                <Grid item xs={12} sm={6} md={3} lg={3} key={index} sx={{ p: 1 }}>
                  <img
                    alt={index}
                    onClick={() => {
                      setOpen(true);
                      setIndex(`${value?.uuid}-${value?.created_at}`);
                    }}
                    className="media-image-container"
                    src={value.thumbnail ? value?.thumbnail : value.url}
                  ></img>
                  <p className="media-description">{value?.description}</p>
                </Grid>
              );
            })
            : pdf
              ?.filter((value) => selected === value.id || selected === value?.url)
              ?.map((value) => {
                return (
                  <Grid item xs={12} sx={{ p: 3, height: "100vh" }}>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js">
                      <Viewer theme="dark" fileUrl={value.url} plugins={[defaultLayoutPluginInstance]} />;
                    </Worker>
                  </Grid>
                );
              })}
          {selected === null ? (
            <Grid item xs={12} sx={{ p: 3 }}>
              <Alert severity="info">No attachments found</Alert>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Box>
      <ImageCarousel images={images} index={index} open={open} handleClose={handleClose} />
    </Box>
  );
};

export default Attachments;
