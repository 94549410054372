import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { Button, Grid, Typography } from "@mui/material";
import { useMediaQuery } from "@material-ui/core";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";

const MediaGallery = (props) => {
  const { mediaFiles, setOpen, setIndex, isCommentMedia, prompt } = props;
  const [expand, setExpand] = useState(false);
  const matches = useMediaQuery("(min-width:600px)");
  const [mediaLength, setMediaLength] = useState(matches ? 4 : 1);
  useEffect(() => {
    if (matches) {
      setMediaLength(4);
    } else setMediaLength(1);
  }, [matches]);
  return (
    <>
      {!isCommentMedia ? <>{mediaFiles?.length > 0 ? <Typography className="line-desc">{prompt}</Typography> : ""}</> : ""}
      {mediaFiles?.length > mediaLength ? (
        <Grid container sx={{ mt: 2, ml: -1 }}>
          {mediaFiles
            ?.slice()
            ?.splice(0, mediaLength)
            ?.map((value) => {
              return (
                <Grid item xs={12} sm={6} md={3} lg={3} sx={{ p: 1 }}>
                  <Grid container>
                    <Grid item xs={12} sx={{ position: "relative" }}>
                      <div style={{ display: "block" }}>
                        <img
                          className="media-image-container"
                          src={value?.type?.includes("image") ? (value.thumbnail ? value.thumbnail : value.onlineUrl) : value.thumbnail}
                          alt="imagess"
                          onClick={() => {
                            setOpen(true);
                            setIndex(`${value?.uuid}-${value?.created_at}`);
                          }}
                        ></img>
                        {value?.type?.includes("video") ? (
                          <PlayCircleIcon
                            className="media-play-icon"
                            onClick={() => {
                              setOpen(true);
                              setIndex(`${value?.uuid}-${value?.created_at}`);
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <p className="media-description" style={{ textAlign: value?.description?.length > 100 ? "justify" : "center" }}>
                        {value?.description}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          {expand
            ? mediaFiles
                ?.slice()
                ?.splice(mediaLength)
                ?.map((value, index) => {
                  return (
                    <Grid item xs={12} sm={6} md={3} lg={3} key={index} sx={{ p: 1 }}>
                      <motion.div initial={{ opacity: 0.0 }} animate={{ opacity: 1 }}>
                        <Grid container>
                          <Grid item xs={12} sx={{ position: "relative" }}>
                            <div style={{ display: "block" }}>
                              <img
                                className="media-image-container"
                                src={value?.type?.includes("image") ? (value.thumbnail ? value.thumbnail : value.onlineUrl) : value.thumbnail}
                                alt="imagess"
                                onClick={() => {
                                  setOpen(true);
                                  setIndex(`${value?.uuid}-${value?.created_at}`);
                                }}
                              ></img>
                              {value?.type?.includes("video") ? (
                                <PlayCircleIcon
                                  className="media-play-icon"
                                  onClick={() => {
                                    setOpen(true);
                                    setIndex(`${value?.uuid}-${value?.created_at}`);
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            <p className="media-description" style={{ textAlign: value?.description?.length > 100 ? "justify" : "center" }}>
                              {value?.description}
                            </p>
                          </Grid>
                        </Grid>
                      </motion.div>
                    </Grid>
                  );
                })
            : ""}
          {/* </Collapse> */}
          <Grid item xs={12}>
            <Button className="view_all" endIcon={expand ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} onClick={() => setExpand(!expand)}>
              View all media
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid container sx={{ mt: 2, ml: -1 }}>
          {mediaFiles?.map((value, index) => {
            return (
              <Grid item xs={12} sm={6} md={3} lg={3} key={index} sx={{ p: 1 }}>
                <Grid container>
                  <Grid item xs={12} sx={{ position: "relative" }}>
                    <div style={{ display: "block" }}>
                      <img
                        className="media-image-container"
                        src={value?.type?.includes("image") ? (value.thumbnail ? value.thumbnail : value.onlineUrl) : value.thumbnail}
                        alt="imagess"
                        onClick={() => {
                          setOpen(true);
                          setIndex(`${value?.uuid}-${value?.created_at}`);
                        }}
                      ></img>
                      {value?.type?.includes("video") ? (
                        <PlayCircleIcon
                          className="media-play-icon"
                          onClick={() => {
                            setOpen(true);
                            setIndex(`${value?.uuid}-${value?.created_at}`);
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <p className="media-description" style={{ textAlign: value?.description?.length > 100 ? "justify" : "center" }}>
                      {value?.description}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
};

export default MediaGallery;