import { checkFiltersLines, checkMultipleRating } from "../../helpers/HelperFunctions";
import CommentsSection from "../../pages/fullReport/CommentsSection";
import MediaGallery from "../../common/MediaGallery/MediaGallery";
import { Divider, Grid, Paper, Typography } from "@mui/material";
import ImageCarousel from "../../common/Carousel/ImageCarousel";
import "../../../assets/styles/containers/_lineCategory.scss";
import { useLocation } from "react-router-dom";
// import parse from "html-react-parser";
import { useState } from "react";

const LineCategory = (props) => {
  let media = [];
  const location = useLocation();
  const pathName = location.pathname;
  const [index, setIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  // eslint-disable-next-line
  const { lineContent, categoryIndex, selectedRatings, categoriesData, setReRender, getReportData, templateId, categoryId } = props;

  function checkSummaryLine(lineitem) {
    let summaryStatus = [];
    lineitem.comments.forEach((comment) => {
      comment?.comment_input_controls
        ?.find((commentCntrl) => commentCntrl.type === "Rating")
        ?.data?.forEach((rating) => {
          if (rating.include_in_summary === "1" || rating.include_in_summary === 1) summaryStatus.push("true");
          else summaryStatus.push("false");
        });
    });
    if (summaryStatus.some((el) => el === "true")) return true;
    else return false;
  }

  function checkSummaryComment(comnt) {
    let commentSumamryStatus = [];
    comnt?.comment_input_controls
      ?.find((commentCntrl) => commentCntrl.type === "Rating")
      ?.data.forEach((rating) => {
        if (rating.include_in_summary === "1" || rating?.include_in_summary === 1) commentSumamryStatus.push("true");
        else commentSumamryStatus.push("false");
      });
    if (commentSumamryStatus.some((el) => el === "true")) return true;
    else return false;
  }

  function convertFontTagsToInlineStyles(htmlString) {
    // Replace <font> tags with inline styles
    var convertedString = htmlString?.replace(/<font([^>]*)>/gi, function (match, p1) {
      var attributes = p1?.split(/\s+/);
      var styles = "";
      const fontSizeConverter = (val) => {
        if (val === "1") {
          return 10;
        } else if (val === "2") {
          return 13;
        } else if (val === "3") {
          return 16;
        } else if (val === "4") {
          return 18;
        } else if (val === "5") {
          return 24;
        } else if (val === "6") {
          return 32;
        } else if (val === "7") {
          return 48;
        } else return val;
      };
      // Convert font attributes to inline styles
      for (var i = 0; i < attributes?.length; i++) {
        var attribute = attributes[i]?.trim();
        var parts = attribute?.split("=");

        if (parts.length === 2) {
          var name = parts[0]?.toLowerCase();
          var value = parts[1]?.replace(/['"]/g, "");
          // eslint-disable-next-line
          switch (name) {
            case "color":
              styles += "color: " + value + "; ";
              break;
            case "size":
              styles += "font-size: " + fontSizeConverter(value) + "px;";
              break;
          }
        }
      }

      return "<span style='" + styles + "'>";
    });
    convertedString = convertedString?.replace(/<\/font>/gi, "</span>");

    return convertedString;
  }

  const createReportContent = (lineItem, lineIndex, categoryIndex, pathName) => {
    // hiding the deleted lines from reportwriter
    if (lineItem.is_deleted === 1) return false;
    const Medias = lineItem.line_input_controls.filter((e) => e.type === "Media");
    const mediaFiles = Medias[0]?.data?.filter((e) => e.online === "online");
    const NamePlate = lineItem?.line_input_controls?.filter((e) => e.type === "Nameplate");
    mediaFiles?.forEach((value) => (value.type?.includes("image") ? media.push({ type: value.type, url: value.onlineUrl, media_id: `${value?.uuid}-${value?.created_at}`, desc: value.description, cat: `${categoryIndex}.${lineIndex + 1} ${lineItem.prompt ? lineItem.prompt : ""}` }) : media.push({ type: value.type, thumb: value.thumbnail, media_id: `${value?.uuid}-${value?.created_at}`, desc: value.description, url: value.onlineUrl, cat: `${categoryIndex}.${lineIndex + 1} ${lineItem.prompt ? lineItem.prompt : ""}` })));
    return (
      <Paper className="line-paper" key={lineIndex}>
        <Grid container>
          <Grid item xs={12}>
            <Typography className="line-title">
              {categoryIndex}.{lineIndex + 1} {lineItem?.prompt}
            </Typography>
          </Grid>
          {pathName === "/" && (
            <>
              <Grid item xs={12}>
                {lineItem?.line_input_controls?.map((lineValue) => {
                  return lineValue.type === "Description" && lineValue.data !== null && lineValue.data?.length !== 0 ? (
                    <Typography className="line-desc">
                      {lineValue?.prompt} - <span className="line-value">{lineValue.data?.map((el) => el).join(", ")}</span>
                    </Typography>
                  ) : (
                    ""
                  );
                })}
                {lineItem?.line_input_controls?.map((lineValue) => {
                  return lineValue.type === "Age" && lineValue.data !== null && lineValue.data?.length !== 0 ? (
                    <Typography className="line-desc">
                      Age - <span className="line-value">{lineValue.data?.map((el) => el).join(", ")}</span>
                    </Typography>
                  ) : (
                    ""
                  );
                })}
                {lineItem?.line_input_controls?.map((lineValue) => {
                  return lineValue.type === "Information" && lineValue.data !== null && lineValue.data.length !== 0 && lineValue.data?.toString() !== "<p><br></p>" && lineValue.data[0] !== null ? (
                    <Typography className="line-info">
                      {lineValue?.prompt} - <span className="line-value" dangerouslySetInnerHTML={{ __html: convertFontTagsToInlineStyles(`${lineValue.data}`) }}></span>
                    </Typography>
                  ) : (
                    ""
                  );
                })}
                {lineItem?.line_input_controls?.map((lineValue) => {
                  return lineValue.type === "Additional Input" && lineValue.data !== null && lineValue.data.length !== 0 ? (
                    <Typography className="line-desc">
                      <span className="capitalise">{lineValue.prompt} - </span>
                      <span className="line-value">{lineValue.data?.map((el) => el).join(", ")}</span>
                    </Typography>
                  ) : (
                    ""
                  );
                })}
                <Grid container sx={{ mb: 1, display: "inline", mt: -1 }}>
                  {lineItem?.line_input_controls?.map((lineValue) => {
                    return lineValue.type === "Location" && lineValue.data !== null && lineValue.data.length !== 0 ? (
                      <Typography className="line-desc">
                        <span className="capitalise">{lineValue?.prompt} - </span>
                        {typeof lineValue.data === "string" ? (
                          <span className="line-value">{lineValue.data}</span>
                        ) : typeof lineValue.data === "object" ? (
                          lineValue?.data?.map((item, index) => {
                            return (
                              <>
                                <span className="line-value">
                                  {item}
                                  {index < lineValue?.data?.length - 1 && ", "}
                                </span>
                              </>
                            );
                          })
                        ) : (
                          ""
                        )}
                      </Typography>
                    ) : (
                      ""
                    );
                  })}
                </Grid>
                <Grid container sx={{ mb: 1, display: "inline", mt: -2 }}>
                  {NamePlate?.map((dataValue) => {
                    return (
                      <Grid item xs={12}>
                        <Typography className="line-desc">
                          {dataValue.data.modelNumber || dataValue.data.serialNumber || dataValue.data.onlineUrl ? <span className="capitalise">{dataValue?.prompt}</span> : ""}
                          {dataValue?.data?.onlineUrl && dataValue?.data?.online === "online" && dataValue?.data?.is_deleted !== 1 ? (
                            <Grid item xs={12} sm={6} md={3} lg={3} sx={{ pt: 2, pr: 2, position: "relative" }}>
                              <div style={{ display: "none" }}>{media.push({ type: "image/png", url: dataValue?.data?.onlineUrl, media_id: `${dataValue?.data?.uuid}-${dataValue?.data?.created_at}`, desc: dataValue?.data?.description, cat: `Nameplate` })}</div>
                              <img
                                style={{ display: "block" }}
                                className="media-image-container"
                                src={dataValue?.data?.thumbnail ? dataValue?.data?.thumbnail : dataValue?.data?.onlineUrl}
                                alt="nameplate"
                                onClick={() => {
                                  setOpen(true);
                                  setIndex(`${dataValue?.data?.uuid}-${dataValue?.data?.created_at}`);
                                }}
                              ></img>
                            </Grid>
                          ) : (
                            ""
                          )}
                        </Typography>
                        <div style={{ marginTop: "10px", paddingLeft: "8px" }}>
                          {dataValue.data?.modelNumber ? <span className="line-value" style={{ marginTop: "15px !important" }}>{`Model Number: ${dataValue?.data.modelNumber}`}</span> : ""}
                          <br />
                          {dataValue?.data?.serialNumber ? <span className="line-value">Serial Number: {dataValue?.data?.serialNumber}</span> : ""}
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>

              {/* Media Section */}
              <MediaGallery isCommentMedia={false} prompt={Medias[0]?.prompt} mediaFiles={mediaFiles} setIndex={setIndex} setOpen={setOpen} />
              <Grid item xs={12} style={{ height: "1px", backgroundColor: "#CCCCCC", marginTop: "15px" }}></Grid>
            </>
          )}
          {/* comment section */}
          {lineItem.comments.length >= 1 ? (
            <Typography className="line-desc" sx={{ fontSize: "15px !important" }}>
              Comments
            </Typography>
          ) : (
            ""
          )}
          {pathName !== "/repair-list" && lineItem.comments.map((comment, index) => (pathName === "/summary" ? checkSummaryComment(comment) && createCommentContent(lineItem, comment, index) : createCommentContent(lineItem, comment, index)))}
          {/* filtration of comments by rating in request list page */}
          {pathName === "/repair-list" &&
            lineItem.comments.map((comment, index) => {
              let currentCommentRatingArray = comment.comment_input_controls?.find((rating) => rating.type === "Rating")?.data;
              return checkMultipleRating(currentCommentRatingArray, selectedRatings) && createCommentContent(lineItem, comment, index, templateId, categoryId);
            })}
        </Grid>
      </Paper>
    );
  };

  const CreatePropmt = (comment, prompt) => {
    if (comment?.prompt && comment?.prompt !== "null" && !comment?.recommendation) {
      return comment?.prompt;
    } else if (comment?.recommendation && !comment?.prompt) {
      return comment?.recommendation?.replace(/<\/?[^>]+(>|$)/g, "");
    } else if (comment?.prompt && comment?.prompt !== "null" && comment?.recommendation) {
      return comment?.prompt;
    } else if (!comment?.recommendation && !comment?.prompt) {
      return "";
    }
  };

  const createCommentContent = (lineItem, comment, index, templateId, categoryId) => {
    // hiding the deleted comments from reportwriter
    if (comment.is_selected === 0) return false;
    return (
      <div className="comment__container" style={{ width: "100%", paddingTop: "10px" }}>
        <CommentsSection {...comment} selectedRatings={selectedRatings} Comprompt={CreatePropmt(comment, lineItem.prompt ? lineItem.prompt : "")} setReRender={setReRender} getReportData={getReportData} templateId={templateId} categoryId={categoryId} lineUuid={lineItem?.uuid} />
        {lineItem.comments.length - 1 === index ? "" : <Divider />}
      </div>
    );
  };

  return (
    <>
      <ImageCarousel images={media} index={index} open={open} handleClose={handleClose} />
      {lineContent?.map((lineItem, lineIndex) => (pathName === "/summary" ? checkSummaryLine(lineItem) && createReportContent(lineItem, lineIndex, categoryIndex, pathName) : pathName === "/repair-list" ? checkFiltersLines(lineItem, selectedRatings) && createReportContent(lineItem, lineIndex, categoryIndex, pathName) : createReportContent(lineItem, lineIndex, categoryIndex, pathName)))}
    </>
  );
};

export default LineCategory;